<template>
  <div>
    <div class="d-flex justify-content-center text-center mt-2">
      <b-row>
        <b-col md="12">
          <div>
            <h1><strong>{{ $t('demand_detail.title') }}</strong></h1>
          </div>
        </b-col>
        <b-col md="12">
          <div>
            <small>{{ $t('demand_detail.under_title5') }}</small>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- <hr class="invoice-spacing"> -->

    <b-card-body class="invoice-padding pt-0">
      <div class="d-flex justify-content-center text-center mb-1">
        <b-row>
          <h6 class="mb-25">
            {{ validOutput ? validOutput.mois : "" }}
            {{ validOutput ? validOutput.annee : "" }}
          </h6>
        </b-row>
      </div>
    </b-card-body>
    <b-card-body class="invoice-padding d-flex justify-content-end">
      <b-button v-if="showCheckButtons" variant="primary" class="btn-icon" size="sm" @click="selectAll()">
        <i class="las la-list-alt iconSmall" /> {{ $t('demand_detail.validate') }}
      </b-button>
    </b-card-body>

    <b-card-body class="invoice-padding">
      <div class="pl-1 pr-1">
        <b-row>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.departement_adresse_residence === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.departement_adresse_residence === true"
                class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.departement_adresse_residence ===
                'to_be_confirmed' && 'font-italic font-bold'
                ">
                {{ $t('demand_detail.validate') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto">
                {{
                  validOutput.departement_adresse_residence != null
                  ? validOutput.departement_adresse_residence
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.departement_adresse_residence" class="col-6" @change="
                update_object(
                  'departement_adresse_residence',
                  validOutput.departement_adresse_residence
                )
                " />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.departement_adresse_residence,
                    'departement_adresse_residence'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.commune_adresse_residence === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.commune_adresse_residence === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.commune_adresse_residence ===
                'to_be_confirmed' && 'font-italic font-bold'
                ">
                Commune:&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto">
                {{
                  validOutput.commune_adresse_residence != null
                  ? validOutput.commune_adresse_residence
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.commune_adresse_residence" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.commune_adresse_residence,
                    'commune_adresse_residence'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.arrondissement_adresse_residence === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.arrondissement_adresse_residence === true"
                class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.arrondissement_adresse_residence ===
                'to_be_confirmed' && 'font-italic'
                ">Arrondissement:&nbsp;</span>
              <strong v-if="!canUpdate" class="my-auto">
                {{
                  validOutput.arrondissement_adresse_residence != null
                  ? validOutput.arrondissement_adresse_residence
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.arrondissement_adresse_residence" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.arrondissement_adresse_residence,
                    'arrondissement_adresse_residence'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.quartier_adresse_residence === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.quartier_adresse_residence === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.quartier_adresse_residence ===
                'to_be_confirmed' && 'font-italic'
                ">{{ $t('demand_detail.village') }}&nbsp;</span>
              <strong v-if="!canUpdate" class="my-auto">
                {{
                  validOutput.quartier_adresse_residence != null
                  ? validOutput.quartier_adresse_residence
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.quartier_adresse_residence" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.quartier_adresse_residence,
                    'quartier_adresse_residence'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
        </b-row>
      </div>
    </b-card-body>
    <hr />
    <!-- renseignement generaux -->
    <b-card-body class="invoice-padding">
      <div class="pl-1 pr-1 mt-1">
        <b-row class="d-flex justify-content-start">
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.nom === false && 'border-danger customPadding'
              ">
              <i v-if="check_object.nom === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.nom === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                {{ $t('demand_detail.name_promoter') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto">
                {{ validOutput.nom != null ? validOutput.nom : "-" }}
              </strong>
              <b-form-input v-else v-model="validOutput.nom" class="col-6"
                @change="update_object('nom', validOutput.nom)" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
                @mouseover="hoverTooltip(check_object.nom, 'nom')">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.prenoms === false && 'border-danger customPadding'
              ">
              <i v-if="check_object.prenoms === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.prenoms === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
               {{ $t('demand_detail.firstname') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.prenoms === 'to_be_confirmed' && 'font-italic'
                ">
                {{ validOutput.prenoms != null ? validOutput.prenoms : "-" }}
              </strong>
              <b-form-input v-else v-model="validOutput.prenoms" class="col-6"
                @change="update_object('prenoms', validOutput.prenoms)" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
                @mouseover="hoverTooltip(check_object.prenoms, 'prenoms')">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.nationalite === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.nationalite === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.nationalite === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                {{ $t('demand_detail.nationality') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.nationalite === 'to_be_confirmed' &&
                'font-italic'
                ">
                {{
                  validOutput.nationalite != null
                  ? validOutput.nationalite
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.nationalite" class="col-6"
                @change="update_object('nationalite', validOutput.nationalite)" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(check_object.nationalite, 'nationalite')
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.date_naissance === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.date_naissance === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.date_naissance === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                 {{ $t('demand_detail.birthday') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.date_naissance === 'to_be_confirmed' &&
                'font-italic'
                ">
                {{
                  validOutput.date_naissance != null
                  ? validOutput.date_naissance
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.date_naissance" class="col-6" @change="
                update_object('date_naissance', validOutput.date_naissance)
                " />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(check_object.date_naissance, 'date_naissance')
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.npi === false && 'border-danger customPadding'
              ">
              <i v-if="check_object.npi === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.npi === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                NPI:&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.npi === 'to_be_confirmed' && 'font-italic'">
                {{ validOutput.npi != null ? validOutput.npi : "-" }}
              </strong>
              <b-form-input v-else v-model="validOutput.npi" class="col-6"
                @change="update_object('npi', validOutput.npi)" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
                @mouseover="hoverTooltip(check_object.npi, 'npi')">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>

          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.genre === false && 'border-danger customPadding'
              ">
              <i v-if="check_object.genre === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.genre === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                 {{ $t('demand_detail.gender') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.genre === 'to_be_confirmed' && 'font-italic'
                ">
                {{ validOutput.genre != null ? validOutput.genre : "-" }}
              </strong>
              <b-form-input v-else v-model="validOutput.genre" class="col-6"
                @change="update_object('genre', validOutput.genre)" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
                @mouseover="hoverTooltip(check_object.genre, 'genre')">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.pays_naissance === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.pays_naissance === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.pays_naissance === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                 {{ $t('demand_detail.country_promoter') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.pays_naissance === 'to_be_confirmed' &&
                'font-italic'
                ">
                {{
                  validOutput.pays_naissance != null
                  ? validOutput.pays_naissance
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.pays_naissance" class="col-6" @change="
                update_object('pays_naissance', validOutput.pays_naissance)
                " />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(check_object.pays_naissance, 'pays_naissance')
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.commune_naissance === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.commune_naissance === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.commune_naissance === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                {{ $t('demand_detail.birth_commune') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.commune_naissance === 'to_be_confirmed' &&
                'font-italic'
                ">
                {{
                  validOutput.commune_naissance != null
                  ? validOutput.commune_naissance
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.commune_naissance" class="col-6" @change="
                update_object(
                  'commune_naissance',
                  validOutput.commune_naissance
                )
                " />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.commune_naissance,
                    'commune_naissance'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.complement_lieu_naissance === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.complement_lieu_naissance === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.complement_lieu_naissance ===
                'to_be_confirmed' && 'font-italic font-bold'
                ">
                {{ $t('demand_detail.complement') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.complement_lieu_naissance ===
                'to_be_confirmed' && 'font-italic'
                ">
                {{
                  validOutput.complement_lieu_naissance != null
                  ? validOutput.complement_lieu_naissance
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.complement_lieu_naissance" class="col-6" @change="
                update_object(
                  'complement_lieu_naissance',
                  validOutput.complement_lieu_naissance
                )
                " />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.complement_lieu_naissance,
                    'complement_lieu_naissance'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.pays_adresse_residence === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.pays_adresse_residence === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.pays_adresse_residence === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                 {{ $t('demand_detail.residence') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.pays_adresse_residence === 'to_be_confirmed' &&
                'font-italic'
                ">
                {{
                  validOutput.pays_adresse_residence != null
                  ? validOutput.pays_adresse_residence
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.pays_adresse_residence" class="col-6" @change="
                update_object(
                  'pays_adresse_residence',
                  validOutput.pays_adresse_residence
                )
                " />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.pays_adresse_residence,
                    'pays_adresse_residence'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>

          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.complement_adresse_residence === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.complement_adresse_residence === true"
                class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.complement_adresse_residence ===
                'to_be_confirmed' && 'font-italic font-bold'
                ">
                {{ $t('demand_detail.complement_residence') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.complement_adresse_residence ===
                'to_be_confirmed' && 'font-italic'
                ">
                {{
                  validOutput.complement_adresse_residence != null
                  ? validOutput.complement_adresse_residence
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.complement_adresse_residence" class="col-6" @change="
                update_object(
                  'complement_adresse_residence',
                  validOutput.complement_adresse_residence
                )
                " />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.complement_adresse_residence,
                    'complement_adresse_residence'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.debut_periode_resident === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.debut_periode_resident === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.debut_periode_resident === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                 {{ $t('demand_detail.start_period') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.debut_periode_resident === 'to_be_confirmed' &&
                'font-italic'
                ">
                {{
                  validOutput.debut_periode_resident != null
                  ? validOutput.debut_periode_resident
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.debut_periode_resident" class="col-6" @change="
                update_object(
                  'debut_periode_resident',
                  validOutput.debut_periode_resident
                )
                " />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.debut_periode_resident,
                    'debut_periode_resident'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>

          <b-col cols="12" xl="6" class="mb-1 text-left">
            <span class="d-flex justify-content-start" :class="check_object.fin_periode_resident === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.fin_periode_resident === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.fin_periode_resident === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                {{ $t('demand_detail.end_period') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.fin_periode_resident === 'to_be_confirmed' &&
                'font-italic'
                ">
                {{
                  validOutput.fin_periode_resident != null
                  ? validOutput.fin_periode_resident
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.fin_periode_resident" class="col-6" @change="
                update_object(
                  'fin_periode_resident',
                  validOutput.fin_periode_resident
                )
                " />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.fin_periode_resident,
                    'fin_periode_resident'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.telephone === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.telephone === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.telephone === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                {{ $t('demand_detail.telephone') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.telephone === 'to_be_confirmed' && 'font-italic'
                ">
                {{
                  validOutput.user.profile.telephone_promoteur != null ? validOutput.user.profile.telephone_promoteur : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.user.profile.telephone_promoteur" class="col-6"
                @change="update_object('telephone', validOutput.user.profile.telephone_promoteur)" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
                @mouseover="hoverTooltip(check_object.telephone, 'telephone')">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.email === false && 'border-danger customPadding'
              ">
              <i v-if="check_object.email === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.email === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                Email:&nbsp;
              </span>

              <strong v-if="!canUpdate" class="my-auto" :class="check_object.email === 'to_be_confirmed' && 'font-italic'
                ">
                {{ validOutput.email != null ? validOutput.email : "-" }}
              </strong>
              <b-form-input v-else v-model="validOutput.email" class="col-6"
                @change="update_object('email', validOutput.email)" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
                @mouseover="hoverTooltip(check_object.email, 'email')">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>

          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.nom_pere === false && 'border-danger customPadding'
              ">
              <i v-if="check_object.nom_pere === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.nom_pere === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                {{ $t('demand_detail.father_lasstname') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.nom_pere === 'to_be_confirmed' && 'font-italic'
                ">
                {{ validOutput.nom_pere != null ? validOutput.nom_pere : "-" }}
              </strong>
              <b-form-input v-else v-model="validOutput.nom_pere" class="col-6"
                @change="update_object('nom_pere', validOutput.nom_pere)" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
                @mouseover="hoverTooltip(check_object.nom_pere, 'nom_pere')">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.prenoms_pere === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.prenoms_pere === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.prenoms_pere === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                 {{ $t('demand_detail.father_firstname') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.prenoms_pere === 'to_be_confirmed' &&
                'font-italic'
                ">
                {{
                  validOutput.prenoms_pere != null
                  ? validOutput.prenoms_pere
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.prenoms_pere" class="col-6" @change="
                update_object('prenoms_pere', validOutput.prenoms_pere)
                " />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(check_object.prenoms_pere, 'prenoms_pere')
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.nom_mere === false && 'border-danger customPadding'
              ">
              <i v-if="check_object.nom_mere === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.nom_mere === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                 {{ $t('demand_detail.mother_lastname') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.nom_mere === 'to_be_confirmed' && 'font-italic'
                ">
                {{ validOutput.nom_mere != null ? validOutput.nom_mere : "-" }}
              </strong>
              <b-form-input v-else v-model="validOutput.nom_mere" class="col-6"
                @change="update_object('nom_mere', validOutput.nom_mere)" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
                @mouseover="hoverTooltip(check_object.nom_mere, 'nom_mere')">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.prenoms_mere === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.prenoms_mere === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.prenoms_mere === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                 {{ $t('demand_detail.mother_firstname') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.prenoms_mere === 'to_be_confirmed' &&
                'font-italic'
                ">
                {{
                  validOutput.prenoms_mere != null
                  ? validOutput.prenoms_mere
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.prenoms_mere" class="col-6" @change="
                update_object('prenoms_mere', validOutput.prenoms_mere)
                " />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(check_object.prenoms_mere, 'prenoms_mere')
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>

          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.situation_matrimoniale === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.situation_matrimoniale === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.situation_matrimoniale === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                {{ $t('demand_detail.marital_status') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.situation_matrimoniale === 'to_be_confirmed' &&
                'font-italic'
                ">
                {{
                  validOutput.situation_matrimoniale != null
                  ? validOutput.situation_matrimoniale
                  : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.situation_matrimoniale" class="col-6" @change="
                update_object(
                  'situation_matrimoniale',
                  validOutput.situation_matrimoniale
                )
                " />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon" @mouseover="
                  hoverTooltip(
                    check_object.situation_matrimoniale,
                    'situation_matrimoniale'
                  )
                  ">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.nb_enfants === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.nb_enfants === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.nb_enfants === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                {{ $t('demand_detail.nbr_kids') }}&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.nb_enfants === 'to_be_confirmed' && 'font-italic'
                ">
                {{
                  validOutput.nb_enfants != null ? validOutput.nb_enfants : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.nb_enfants" class="col-6"
                @change="update_object('nb_enfants', validOutput.nb_enfants)" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
                @mouseover="hoverTooltip(check_object.nb_enfants, 'nb_enfants')">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            <span class="d-flex justify-content-start" :class="check_object.profession === false &&
              'border-danger customPadding'
              ">
              <i v-if="check_object.profession === true" class="las la-dot-circle icon_primary my-auto" />
              <span class="my-auto" :class="check_object.profession === 'to_be_confirmed' &&
                'font-italic font-bold'
                ">
                Profession:&nbsp;
              </span>
              <strong v-if="!canUpdate" class="my-auto" :class="check_object.profession === 'to_be_confirmed' && 'font-italic'
                ">
                {{
                  validOutput.profession != null ? validOutput.profession : "-"
                }}
              </strong>
              <b-form-input v-else v-model="validOutput.profession" class="col-6"
                @change="update_object('profession', validOutput.profession)" />
              <!-- tooltip -->
              <b-button v-if="showCheckButtons" v-b-tooltip.hover.top.v-light="renderTooltip"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="flat-light" class="btn-icon"
                @mouseover="hoverTooltip(check_object.profession, 'profession')">
                <i class="las la-clipboard-check iconSmall" />
              </b-button>
            </span>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="12" xl="6" class="mb-1">
            {{ $t('demand_detail.number_demand') }}&nbsp;
            <strong>
              {{
                validOutput.numero_demande != null
                ? validOutput.numero_demande
                : "-"
              }}
            </strong>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            {{ $t('demand_detail.date') }}&nbsp;
            <strong>
              {{
                validOutput.date_demande != null
                ? formattedDate(validOutput.date_demande)
                : "-"
              }}
            </strong>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            {{ $t('demand_detail.amount') }}
            <strong>
              {{ validOutput.montant != null ? validOutput.montant : "-" }}
            </strong>
          </b-col>
          <b-col cols="12" xl="6" class="mb-1">
            {{ $t('demand_detail.payment_date') }}
            <strong>
              {{
                validOutput.date_paiement != null
                ? formattedDate(validOutput.date_paiement)
                : "-"
              }}
            </strong>
          </b-col>
        </b-row>
      </div>

    <div class="bg-primary mt-2">
      <b-row class="text-center py-1 px-1">
        <b-col
          md="12"
          class="text-center text-white d-flex align-items-center justify-content-between"
        >
          <strong> {{ $t('demand_detail.commission_decision') }}</strong>
        </b-col>
      </b-row>
    </div>
    <b-card-body class="invoice-padding">
      <b-row>
        <b-col cols="12" xl="6" class="mb-1">
          {{ $t('demand_detail.type_establishment') }}&nbsp;
          <strong>
            {{
              validOutput.data.type_etablissement != null ? validOutput.data.type_etablissement : '-'
            }}
          </strong>
        </b-col>
        <b-col cols="12" xl="6" class="mb-1">
          {{ $t('demand_detail.classification_desired') }}&nbsp;
          <strong>
            {{
              validOutput.classement != null ? validOutput.classement : '-'
            }}
          </strong>
        </b-col>
      </b-row>
    </b-card-body>
    </b-card-body>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCol,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BPopover,
  BButton,
  VBTooltip,
  BTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import Logo from "@core/layouts/components/Logo.vue";
import AppLogo from "@/components/AppLogo.vue";
import RequestDetailsCheckComponent from "@/components/RequestDetailsCheckComponent.vue";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import paramsStoreModule from "@/store/params";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BCol,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    vSelect,
    Logo,
    BPopover,
    BButton,
    AppLogo,
    RequestDetailsCheckComponent,
    BTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    validOutput: {
      type: Object,
      default: () => { },
    },
    showCheckButtons: {
      type: Boolean,
      default: () => false,
    },
    canUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      options_check: [
        {
          title: 'options_check.title1',
          value: true,
          variant: 'primary',
          icon: 'la-check',
        },
        {
          title: 'options_check.title2',
          value: false,
          variant: 'danger',
          icon: 'la-times',
        },
        {
          title: 'options_check.title3',
          value: 'to_be_confirmed',
          variant: 'secondary',
          icon: 'la-question',
        },
      ],

      current_check_value: null,
      current_check_attribut: null,
    };
  },
  computed: {
    ...mapGetters("params", {
      getter_current_check_value: "getter_current_check_value",
    }),
    // --------------------------------------------------------
    check_object() {
      return this.validOutput && this.validOutput.check_object;
    },
  },
  setup() {
    const requiredStoreModules = [
      { path: "params", module: paramsStoreModule },
    ];
    registerStoreModule(requiredStoreModules);
  },
  watch: {
    $route: {
      immediate: true,
      async handler(val, old) { },
    },
    getter_current_check_value: {
      immediate: false,
      async handler(val, old) {
        // console.log("val::: ⭕️⭕️⭕️", val, this.current_check_attribut);
        this.check_object[this.current_check_attribut] =
          this.getter_current_check_value;
        this.$emit("on_change_check_object", this.check_object);
      },
    },
  },
  mounted() { },
  methods: {
    formattedDate(dateValue) {
      // Create a new Date object from the original date string
      const date = new Date(dateValue);
      // Format the date manually
      const formattedDateString = `${date
        .getDate()
        .toString()
        .padStart(2, "0")}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date.getFullYear()} ${date
            .getHours()
            .toString()
            .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
      return formattedDateString;
    },
    renderTooltip() {
      const props = {
        model: this.current_check_value,
      };
      const requestDetailsCheckComponent = this.$createElement(
        "requestDetailsCheckComponent",
        { props }
      );
      return requestDetailsCheckComponent;
    },
    hoverTooltip(current_model, attribut) {
      this.current_check_value = current_model;
      this.current_check_attribut = attribut;
    },

    selectAll() {
      for (const key in this.check_object) {
        if (this.check_object.hasOwnProperty(key)) {
          this.check_object[key] = this.options_check[0].value;
          this.hoverTooltip(this.check_object[key], key);
          this.$emit("on_change_check_object", this.check_object);
        }
      }
    },
    update_object(attribut, value) {
      this.$emit("on_change_object", this.validOutput);
      // this.$emit("on_change_object", { attribut, value });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";

.title-background {
  background-color: #ababab;
}

.tableHeadFontSize {
  font-size: 10px;
}

.bd-none {
  border: none;
}
</style>
